<template>
	<div>
		<!-- 系统通道管理 -->
		<template>
		<div class="queryBox">
			<div class="mb10">
				<span>{{$t('main.orderMenu.collectionMethod')}}：</span>
				<el-select v-model="pay_type" :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr20" clearable>					
					<el-option v-for="item in payTypeArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<span>代付方式：</span>
				<el-select v-model="repay_type" :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr20" clearable>					
					<el-option v-for="item in repayTypeArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">{{$t('main.orderMenu.query')}}</el-button>
				
			</div>
			
		</div>
		<div class="flexX flexBetween mt10">
			<div>
				<el-button size="mini" type="success" plain @click="showAllLog()">额度变动日志</el-button>
			</div>
			<div class="flexX flexEnd">
				  <el-select
					class="mb10"
					size="mini"
					v-model="CheckedCol"
					@change="colChange"
					multiple
					collapse-tags
					style="margin-left: 20px;"
					placeholder="请选择">
					<el-option
					  v-for="item in CheckColumn"
					  :key="item.value"
					  :label="item.label"
					  :value="item.value">
					</el-option>
				  </el-select>
			</div>
		</div>
			<el-table :data="tableData" border  size="mini" :span-method="objectSpanMethod" >
				<el-table-column v-if="CheckedCol.includes('name')" fixed="left" prop="" :label="$t('main.passagewayMenu.passagewayName')" width="100px">
					<template slot-scope="scope">
						<!-- 【{{scope.row.abbre}}】 -->
						{{scope.row.name}}
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('pay_type_list')" prop="pay_type_list" :label="$t('main.passagewayMenu.collectionMethod')">
					<template slot-scope="scope">
						
						<el-dropdown v-if="scope.row.pay_type_list.length>0">
						  <span class="el-dropdown-link">
								  <el-tag size="mini" type="success" v-if="scope.row.pay_type_list.length>1">[{{Object.values(scope.row.pay_type_list)[0].id}}] {{Object.values(scope.row.pay_type_list)[0].name}} +{{scope.row.pay_type_list.length}}</el-tag></i>
						  </span>
						  <el-dropdown-menu slot="dropdown">
						    <el-dropdown-item  v-for="pay_type in scope.row.pay_type_list" :key="pay_type.id">[{{pay_type.id}}] {{pay_type.name}}</el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>
						<el-tag size="mini" type="success" v-if="scope.row.pay_type_list.length==1">[{{Object.values(scope.row.pay_type_list)[0].id}}] {{Object.values(scope.row.pay_type_list)[0].name}}</el-tag>
						<span v-if="scope.row.pay_type_list.length == 0">-</span>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('repay_type_list')" prop="repay_type_list" :label="$t('main.passagewayMenu.paymentMethod')" >
					<template slot-scope="scope">
						<el-dropdown v-if="scope.row.repay_type_list.length>1">
						  <span class="el-dropdown-link">
								  <el-tag size="mini" type="success" v-if="scope.row.repay_type_list.length>1">[{{Object.values(scope.row.repay_type_list)[0].id}}] {{Object.values(scope.row.repay_type_list)[0].name}}<span class="cdanger" v-if="Object.values(scope.row.repay_type_list)[0].max_gold">|￥{{Object.values(scope.row.repay_type_list)[0].min_gold}}~{{Object.values(scope.row.repay_type_list)[0].max_gold}}</span> +{{scope.row.repay_type_list.length}}</el-tag></i>
						  </span>
						  <el-dropdown-menu slot="dropdown">
						    <el-dropdown-item  v-for="pay_type in scope.row.repay_type_list" :key="pay_type.id">[{{pay_type.id}}] {{pay_type.name}}<span class="cdanger" v-if="pay_type.max_gold">|￥{{pay_type.min_gold}}~{{pay_type.max_gold}}</span></el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>
						<el-tag size="mini" type="success" v-if="scope.row.repay_type_list.length==1">[{{Object.values(scope.row.repay_type_list)[0].id}}] {{Object.values(scope.row.repay_type_list)[0].name}}<span class="cdanger" v-if="Object.values(scope.row.repay_type_list)[0].max_gold">|￥{{Object.values(scope.row.repay_type_list)[0].min_gold}}~{{Object.values(scope.row.repay_type_list)[0].max_gold}}</span></el-tag>
						<span v-if="scope.row.repay_type_list.length == 0">-</span>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('weight')" prop="" label="权重" width="50px">
					<template slot-scope="scope">
						{{scope.row.weight}}
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('balance')" label="实时余额" >
					<template slot-scope="scope">
						<div class="flexX flexBetween">
							<span>{{scope.row.balance | tofixed_two}}</span>
							<span>
								<i v-if="!scope.row.yeloading" class="el-icon-refresh-right fs18" @click="getBalance(scope.row.id, 1)"></i>
								<i v-if="scope.row.yeloading" class="el-icon-loading fs18"></i>
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('pay_money')" label="通道余额">
					<template slot-scope="scope">
						<div class="flexX flexBetween">
							<span v-if="scope.row.pay_money">{{scope.row.pay_money | tofixed_two}}</span>
							<span v-else>-</span>
							<span><el-link type="primary" @click="openDia('tdye',scope.row)">调额</el-link></span>
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('repaid_money')" label="代收预付"  width="155px">
					<template slot-scope="scope">
						<div class="flexX flexBetween">
							<span>{{scope.row.repaid_money | tofixed}}</span>
							<span class="flexX flexStart">
								<el-link class="mr10" type="primary" @click="jiesuan(scope.row)">结算</el-link>
								<el-link type="primary" @click="openDia('dsyufu',scope.row)">调预付</el-link>
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('remaind_repaid')" label="净预付" >
					<template slot-scope="scope">
						
						<div class="flexX flexBetween">
							<span >
								<span v-if="scope.row.remaind_repaid>=0">{{scope.row.remaind_repaid | tofixed}}</span>
								<span v-if="scope.row.remaind_repaid<0" class="cdanger">{{scope.row.remaind_repaid | tofixed}}</span>
							</span>
							<span>
								<el-link class="mr10" type="primary" @click="jsmx(scope.row)">明细</el-link>
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('credit_money')" label="代收信用额度" >
					<template slot-scope="scope">
						<div class="flexX flexBetween">
							<span>{{scope.row.credit_money | tofixed}}</span>
							<span><el-link type="primary" @click="openDia('dsxyed',scope.row)">调额</el-link></span>
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('df_credit_money')" label="代付余额" >
					<template slot-scope="scope">
						<div class="flexX flexBetween">
							<span>{{scope.row.df_credit_money | tofixed}}</span>
							<span><el-link type="primary" @click="openDia('dfxyed',scope.row)">调额</el-link></span>
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('stateB')" :label="$t('main.passagewayMenu.mainSwitch')"  width="65px">
					<template slot-scope="scope">
						<el-switch :disabled="disLoading" @change="setStatePayway(scope.row)" v-model="scope.row.stateB" class="mr20"></el-switch>
						<!-- <span>{{scope.row.stateB?$t('main.passagewayMenu.open'):$t('main.passagewayMenu.close')}}</span> -->
					
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('pay_stateB')" :label="$t('main.passagewayMenu.collectionSwitch')"  width="70px">
					<template slot-scope="scope">
						<el-switch :disabled="disLoading" @change="setPayState(scope.row)" v-model="scope.row.pay_stateB" class="mr20"></el-switch>
						<!-- <span>{{scope.row.pay_stateB?$t('main.passagewayMenu.open'):$t('main.passagewayMenu.close')}}</span> -->
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('repay_stateB')" :label="$t('main.passagewayMenu.paymentSwitch')" width="70px">
					<template slot-scope="scope">
						<el-switch :disabled="disLoading" @change="setRepayState(scope.row)" v-model="scope.row.repay_stateB" class="mr20"></el-switch>
						<!-- <span>{{scope.row.repay_stateB?$t('main.passagewayMenu.open'):$t('main.passagewayMenu.close')}}</span> -->
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('operation')" fixed="right" :label="$t('main.passagewayMenu.operation')" >
					<template slot-scope="scope">
						<el-link type="primary" @click="showEdit(scope.row)" class="mr10">编辑</el-link>
						<el-link type="primary" @click="openDrawer(scope.row)" class="mr10">配置</el-link>
						<!-- <el-link type="primary" @click="showDialog('recharge',scope.row)" class="mr20">{{$t('main.passagewayMenu.commissionCollection')}}</el-link>
						<el-link type="primary" @click="showDialog('charge',scope.row)" class="mr20">{{$t('main.passagewayMenu.commissionPayment')}}</el-link> -->
						<!-- <el-link type="primary" @click="showDialog('fee',scope.row)" class="mr20">设置费率</el-link> -->
						<!-- <br> -->
						<!-- <el-link type="primary" @click="showDialog('limit',scope.row)" class="mr20">{{$t('main.passagewayMenu.setLimits')}}</el-link> -->
						<!-- <el-link type="primary" @click="showDialog('paywaySet',scope.row)" class="mr20">通道编号</el-link> -->
						<el-link type="primary" @click="showChooseCard(scope.row, 'pay')" class="mr20">代收产品</el-link>
						<el-link type="primary" @click="showChooseCard(scope.row, 'repay')" class="mr20">代付产品</el-link>
						<el-popconfirm
						  confirm-button-text='确定'
						  cancel-button-text='取消'
						  icon="el-icon-info"
						  icon-color="red"
						  title="确定删除通道吗？"
						  @confirm="delPayway(scope.row.id)"
						>
						  <el-link type="primary" size="mini" slot="reference">删除</el-link>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
		</template>
		
		
		<!--------------------------------- 抽屉操作 START -------------------------------------->
		<div>
			<el-drawer
			  title=""
			  :visible.sync="dwdialog"
			  direction="rtl"
			  custom-class="demo-drawer"
			  ref="drawer"
			  :with-header="false"
			  size='55%'
			  :destroy-on-close="true"
			  :show-close="true"
			  @closed="handleDrawerClosed"
			  >
			  <div  v-loading="dialogloading" class="demo-drawer__content">
				  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
				    <el-menu-item index="fee">代收手续费</el-menu-item>
				    <el-menu-item index="reFee">代付手续费</el-menu-item>
				    <!-- <el-menu-item index="credit">通道授信</el-menu-item> -->
				    <el-menu-item index="configTra">一键配置商户</el-menu-item>
				  </el-menu>
				  
				  <!-- 代收费率 -->
				  <el-form v-if="activeIndex === 'fee'" :label-position="labelPosition" label-width="100px" class="mb20">
				  	<el-table v-loading="loading" height="550px" :data="tableList" stripe size="mini"  border>
						<el-table-column prop="pay_type_name" :label="$t('main.passagewayMenu.collectionMethod')"  width="160px">
								<template slot-scope="scope">
										【{{scope.row.pay_type}}】{{scope.row.pay_type_name}} 
								</template>
						</el-table-column>
						
						<el-table-column label="手续费(‰)">
							<template slot-scope="scope">
				  				<div class="flexX flexStart">
									 <el-input class="mr10" style="width: 7.5rem;" size="small" placeholder="请输入费率,千分" v-model="scope.row.fee">
									    <template slot="append">‰</template>
									  </el-input>
				  				</div>
								
							</template>
						</el-table-column>
						<el-table-column label="限额">
							<template slot-scope="scope">
				  				<div class="flexX flexStart">
									<div style="width: 80px;">
									  <el-select class="mr20 custom-select" size="mini" v-model="scope.row.type" placeholder="请选择">
									    <el-option
									      v-for="item in fitoptions"
									      :key="item.value"
									      :label="item.label"
									      :value="item.value">
									    </el-option>
									  </el-select>
									</div>
				  					<el-input v-if="scope.row.type==1" class="ml10 mr10" size="mini" v-model="scope.row.min_gold" placeholder="请输入内容"></el-input>
				  					<el-input v-if="scope.row.type==1" size="mini" v-model="scope.row.max_gold" placeholder="请输入内容"></el-input>
									<div v-if="scope.row.type==2" class="ml10">
										<el-tag
										size="mini"
											v-if="scope.row.fix_gold.length>0"
										 type="success"
										   class="mr10"
										  :key="tag"
										  v-for="tag in scope.row.fix_gold.split(',')"
										  closable
										  :disable-transitions="false"
										  @close="fixhandleClose(tag, scope.row)">
										  {{tag}}
										</el-tag>
										<el-input
										  class="input-new-tag mr10"
										  v-if="scope.row.inputVisible"
										  v-model="scope.row.inputValue"
										  :ref="'saveTagInput' + scope.$index"
										  size="mini"
										  @keyup.enter.native="fixhandleInputConfirm(scope.row)"
										  @blur="fixhandleInputConfirm(scope.row)"
										>
										</el-input>
										<el-button v-else class="button-new-tag" size="mini" @click="fixshowInput(scope.$index)">+</el-button>
									</div>
				  				</div>
							</template>
						</el-table-column>
				  	</el-table>
				  
				  </el-form>
				  
				  <!-- 代付费率 -->
				  <el-form v-if="activeIndex === 'reFee'" :label-position="labelPosition" label-width="80px" class="mb20">
				  	<el-table v-loading="loading" height="550px" :data="repayTableList" stripe size="mini"  border>
						<el-table-column prop="pay_type_name" :label="$t('main.orderMenu.paymentMethod')"  width="160px">
								<template slot-scope="scope">
										【{{scope.row.pay_type}}】{{scope.row.pay_type_name}} 
								</template>
						</el-table-column>
					
						
						<el-table-column label="费率(‰)" width="" align="center">
							<template slot-scope="scope">
				  				<div class="flexX flexStart">
									 <el-input class="mr10" style="width: 7.5rem;" size="small" placeholder="请输入费率,千分" v-model="scope.row.fee">
									    <template slot="append">‰</template>
									  </el-input>
				  				</div>
								
							</template>
						</el-table-column>
						<el-table-column label="单笔(元)" width="" align="center">
							<template slot-scope="scope">
				  				<div class="flexX flexStart">
				  					<el-input class="mr10" style="width: 7.5rem;" size="mini" v-model="scope.row.fee_num" placeholder="请输入内容"></el-input>
				  				</div>
								
							</template>
						</el-table-column>
						<el-table-column label="限额" width="" align="center">
							<template slot-scope="scope">
									<div class="flexX flexStart">
										<el-input class="mr10" size="mini" v-model="scope.row.min_gold" placeholder="请输入内容"></el-input>
										<el-input size="mini" v-model="scope.row.max_gold" placeholder="请输入内容"></el-input>
									</div>
							</template>
						</el-table-column>
				  	</el-table>
				  
				  </el-form>
				  <!-- 授信 -->
				  <!-- <el-form v-if="activeIndex === 'credit'" :label-position="labelPosition" label-width="80px" class="mb20">
						<div class="ml20 mb10">
							<span>通道名称：</span><span>{{dwitem.name}}</span>
						</div>
						<div class="ml20 mb10">
							<span>代付授信(出)：</span><span>{{dwitem.df_credit_money}}</span>
						</div>
						<div class="ml20 mb10">
							<span>代收授信(进)：</span><span>{{dwitem.credit_money}}</span>
						</div>
						<el-form-item class="mt20" label="操作类型">
							<el-radio v-model="dfunmoneyRadio" label="DF">代付(出)</el-radio>
							<el-radio v-model="dfunmoneyRadio" label="DS">代收(进)</el-radio>
						</el-form-item>
						<el-form-item class="mt20" label="增减方式">
							<el-radio v-model="unmoneyRadio" label="IN">增加</el-radio>
							<el-radio v-model="unmoneyRadio" label="OUT">扣除</el-radio>
						</el-form-item>
						<el-form-item class="mt20" label="金额">
							<el-input v-model="unmoneyAmount" placeholder="请输入金额"></el-input>
						</el-form-item>
						<el-form-item class="mt20" label="备注">
							<el-input v-model="unmoneyNote" placeholder="请输入备注"></el-input>
						</el-form-item>
				  
				  </el-form> -->
				  <!-- 一键商户配置 -->
				  <el-form v-if="activeIndex === 'configTra'" :label-position="labelPosition" label-width="80px" class="mb20 formBox drawer-content">
						<el-form-item class="mt20" label="">
							  <el-input readonly :placeholder="dwitem.name" v-model="dwitem.name">
							    <template slot="prepend">通道名称</template>
							  </el-input>
						</el-form-item>
						<el-form-item class="mt20" label="产品类型">
							  <el-select @change="cpChange" v-model="cpType" placeholder="请选择产品类型">
							    <el-option
							      v-for="item in cpTypes"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value">
							    </el-option>
							  </el-select>
						</el-form-item>
						<el-form-item class="mt20" label="产品">
							  <el-select @change="cpDataChange" v-model="cpData" placeholder="请选择产品">
							    <el-option
							      v-for="item in cpDatas"
							      :key="item.id"
							      :label="'【'+item.id+'】'+item.name"
							      :value="item.id">
							    </el-option>
							  </el-select>
						</el-form-item>
						<el-form-item class="mt20" label="商户">
							  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
							  <el-checkbox-group v-model="traderChecked">
							    <el-checkbox border size="mini" v-for="(item,index) in traderList" :key="index" :label="item.business_no">{{ item.business_name }}</el-checkbox>
							  </el-checkbox-group>
						</el-form-item>
				  
				  </el-form>
				  
				  
				  <div class="textCenter drawer-footer">
				  	<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				  </div>
				  
			  </div>
			</el-drawer>
		</div>
		
		<!-- 调额 -->
		<el-dialog center class="dialog" top="100px" :title="teTitle" :visible.sync="teDialog" @close="teClose" :close-on-click-modal="false" width="35%">
			
			<div class="formBox" v-loading="dialogloading">
				<div>
					<el-form :model="amountForm" size="mini" v-if="teType ==='tdye'" :label-position="labelPosition" class="mb20"  label-width="50px">
						
						<div class="flexY flexAround mb20">
							<el-input placeholder="请输入内容" v-model="this.paywayItem.name">
								<template slot="prepend">通道名称:</template>
							</el-input>
						</div>
						<div class="mb20 fs18 ml10">
							当前余额: 
							<span v-if="this.paywayItem.pay_money" class="tabletdColor fs20">￥{{this.paywayItem.pay_money}}</span>
							<span v-else class="tabletdColor fs20">￥0.00</span>
						</div>
						 <el-form-item label="调额:" prop="amount" :rules="{ required: true, message: '调额不能为空'}">
							<el-input v-model="amountForm.amount">
								<template slot="prepend">￥</template>
							</el-input>
							<div>如需扣除余额，则输入负数。例如：-300</div>
						  </el-form-item>
						 <el-form-item label="谷歌:" prop="googleCode" :rules="[{ required: true, message: '谷歌不能为空'},]">
							<el-input v-model="amountForm.googleCode">
							</el-input>
						  </el-form-item>
						 <el-form-item label="备注:" prop="amountNote" >
							<el-input
							  type="textarea"
							  :rows="3"
							  placeholder="请输入备注"
							  v-model="amountForm.amountNote">
							</el-input>
						  </el-form-item>
						
					</el-form>
					
					<el-form :model="amountForm" size="mini" v-if="teType ==='dsxyed'" :label-position="labelPosition" class="mb20"  label-width="50px">
						
						<div class="flexY flexAround mb20">
							<el-input placeholder="请输入内容" v-model="this.paywayItem.name">
								<template slot="prepend">通道名称:</template>
							</el-input>
						</div>
						
						<div class="mb20 fs18 ml10">
							代收信用额度: 
							<span v-if="this.paywayItem.credit_money" class="tabletdColor fs20">￥{{this.paywayItem.credit_money}}</span>
							<span v-else class="tabletdColor fs20">￥0.00</span>
						</div>
						 <el-form-item label="调额:" prop="amount" :rules="{ required: true, message: '调额不能为空'}">
							<el-input v-model="amountForm.amount">
								<template slot="prepend">￥</template>
							</el-input>
							<div>如需扣除余额，则输入负数。例如：-300</div>
						  </el-form-item>
						 <el-form-item label="谷歌:" prop="googleCode" :rules="[{ required: true, message: '谷歌不能为空'},]">
							<el-input v-model="amountForm.googleCode">
							</el-input>
						  </el-form-item>
						 <el-form-item label="备注:" prop="amountNote" >
							<el-input
							  type="textarea"
							  :rows="3"
							  placeholder="请输入备注"
							  v-model="amountForm.amountNote">
							</el-input>
						  </el-form-item>
						
					</el-form>
					
					<el-form :model="amountForm" size="mini" v-if="teType ==='dfxyed'" :label-position="labelPosition" class="mb20"  label-width="50px">
						
						<div class="flexY flexAround mb20">
							<el-input placeholder="请输入内容" v-model="this.paywayItem.name">
								<template slot="prepend">通道名称:</template>
							</el-input>
						</div>
						<div class="mb20 fs18 ml10">
							代付信用额度: 
							<span v-if="this.paywayItem.df_credit_money" class="tabletdColor fs20">￥{{this.paywayItem.df_credit_money}}</span>
							<span v-else class="tabletdColor fs20">￥0.00</span>
						</div>
						 <el-form-item label="调额:" prop="amount" :rules="{ required: true, message: '调额不能为空'}">
							<el-input v-model="amountForm.amount">
								<template slot="prepend">￥</template>
							</el-input>
							<div>如需扣除余额，则输入负数。例如：-300</div>
						  </el-form-item>
						 <el-form-item label="谷歌:" prop="googleCode" :rules="[{ required: true, message: '谷歌不能为空'},]">
							<el-input v-model="amountForm.googleCode">
							</el-input>
						  </el-form-item>
						 <el-form-item label="备注:" prop="amountNote" >
							<el-input
							  type="textarea"
							  :rows="3"
							  placeholder="请输入备注"
							  v-model="amountForm.amountNote">
							</el-input>
						  </el-form-item>
						
					</el-form>
					
					<el-form :model="amountForm" size="mini" v-if="teType ==='dsyufu'" :label-position="labelPosition" class="mb20"  label-width="50px">
						
						<div class="flexY flexAround mb20">
							<el-input placeholder="请输入内容" v-model="this.paywayItem.name">
								<template slot="prepend">通道名称:</template>
							</el-input>
						</div>
						<div class="mb20 fs18 ml10">
							代收预付额度: 
							<span v-if="this.paywayItem.repaid_money" class="tabletdColor fs20">￥{{this.paywayItem.repaid_money}}</span>
							<span v-else class="tabletdColor fs20">￥0.00</span>
						</div>
						
						 <el-form-item label="调额:" prop="amount" :rules="{ required: true, message: '调额不能为空'}">
							<el-input v-model="amountForm.amount">
								<template slot="prepend">￥</template>
							</el-input>
							<div>如需扣除余额，则输入负数。例如：-300</div>
						  </el-form-item>
						 <el-form-item label="谷歌:" prop="googleCode" :rules="[{ required: true, message: '谷歌不能为空'},]">
							<el-input v-model="amountForm.googleCode">
							</el-input>
						  </el-form-item>
						 <el-form-item label="备注:" prop="amountNote" >
							<el-input
							  type="textarea"
							  :rows="3"
							  placeholder="请输入备注"
							  v-model="amountForm.amountNote">
							</el-input>
						  </el-form-item>
						
					</el-form>
					
					<div class="flexX flexEnd" >
						<el-button  v-loading="dialogloading" size="mini" @click="onSubmit()" type="success">确认</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
		
		
		<!-- 结算 -->
		<el-dialog title="结算" width="75%" center :visible.sync="jiesuanDialog"  @close="jiesuanClose" :close-on-click-modal="false" >
			<div class="flexX flexBetween mb10" style="border-bottom: 1px solid black;">
				
				<span>通道名称:{{this.jsItem.name}}</span>
				<span>
					状态:
					<el-tag size="mini" v-if="this.jsData.state == 1" type="success">已结算</el-tag>
					<el-tag size="mini" v-if="this.jsData.state == 0" type="danger">未结算</el-tag>
					</span>
				<span>日期:{{this.jsData.add_date}}</span>
			</div>
			<div class="flexX flexBetween mb10" style="width: 100%; border-bottom: 1px solid black;">
				<div style="border-right: 1px solid black;width: 33%;">
					<el-tag size="mini" class="mr10" type="success">成功：{{this.jsData.totalPaywayInMoney}}</el-tag>
					<el-tag size="mini" type="success">入账：{{this.jsData.totalPaywayEntryMoney}}</el-tag>
					<el-table :data="this.jsData.orderInData" border size="mini">
					  <el-table-column property="pay_type" label="支付方式"></el-table-column>
					  <el-table-column property="success_money" label="成功金额"></el-table-column>
					  <el-table-column property="payway_type_money" label="入账金额"></el-table-column>
					</el-table>
				</div>
				<div style="border-right: 1px solid black;width: 33%;">
					<el-tag size="mini" type="success">通道余额调额：{{this.jsData.totalPayMoney}}</el-tag>
					<el-table :data="this.jsData.moneyPayChangeData" border size="mini">
					  <el-table-column property="money" label="金额">
						  <template slot-scope='scope'>
						  	<div>
								<span class="csuccess" v-if="scope.row.change_type == 'IN'">+{{scope.row.money}}</span>
								<span class="cdanger" v-if="scope.row.change_type == 'OUT'">-{{scope.row.money}}</span>
						  	</div>
						  </template>
					  </el-table-column>
					  <el-table-column property="notes" label="备注" show-overflow-tooltip></el-table-column>
					</el-table>
				</div>
				<div style=" width: 33%;">
					<el-tag size="mini" type="success">预付：{{this.jsData.totalRepaidMoney}}</el-tag>
					<el-table :data="this.jsData.moneyRepaidChangeData" border size="mini">
					  <el-table-column property="money" label="金额">
						  <template slot-scope='scope'>
						  	<div>
								<span class="csuccess" v-if="scope.row.change_type == 'IN'">+{{scope.row.money}}</span>
								<span class="cdanger" v-if="scope.row.change_type == 'OUT'">-{{scope.row.money}}</span>
						  	</div>
						  </template>
					  </el-table-column>
					  <el-table-column property="notes" label="备注" show-overflow-tooltip></el-table-column>
					</el-table>
				</div>
				<!-- <div style="width: 25%;">
					<el-tag size="mini" type="success">预付：{{this.jsData.money_repaid}}</el-tag>
					<el-table :data="this.jsData.moneyRepaidChangeData" border size="mini">
					  <el-table-column property="money" label="金额">
						  <template slot-scope='scope'>
						  	<div>
								<span class="csuccess" v-if="scope.row.change_type == 'IN'">+{{scope.row.money}}</span>
								<span class="cdanger" v-if="scope.row.change_type == 'out'">-{{scope.row.money}}</span>
						  	</div>
						  </template>
					  </el-table-column>
					  <el-table-column property="notes" label="备注" show-overflow-tooltip></el-table-column>
					</el-table>
				</div> -->
				
			</div>
			<div>
				<div class="mb10">应结算:<span ><el-tag size="medium" type="danger">{{this.jsData.shouldSettleMoney}}</el-tag></span></div>
				<div class="mb10">已结算:<span ><el-tag size="mini" type="success">{{this.jsData.alreadySettleMoney}}</el-tag></span></div>
				<div>未结算:<span ><el-tag size="mini" type="info">{{this.jsData.notSettleMoney}}</el-tag></span></div>			
				<div>(通道入账:{{this.jsData.totalPaywayEntryMoney}} + 通道余额调额:{{this.jsData.totalPayMoney}}  - 已结算:{{this.jsData.alreadySettleMoney}} = 应结算:{{this.jsData.shouldSettleMoney}})</div>
			</div>
			<div class="flexX flexEnd" >
				<el-button  v-loading="dialogloading" size="mini" v-if="this.jsData.state == 0" @click="jst()" type="success">结算</el-button>
			</div>
		  
		</el-dialog>
		
		
		<!-- 通道余额明细 -->
		<div>
			<el-drawer
			 size="50%"
			  title="余额明细"
			  :visible.sync="balanceDrawer"
			  direction="ltr"
			  :before-close="balanceHandleClose">
			  <div class="mb10">
				<el-button size="mini" plain class="mr20" @click="setBalanceDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setBalanceDate(1)" :disabled="disLoading">后一天</el-button>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getBalanceData" :disabled="disLoading">{{$t('main.orderMenu.query')}}</el-button>
			</div>
			  
			  	<el-table v-loading="loading" height="550px" :data="balanceList" stripe  border >
					
					<el-table-column prop="" label="变动前余额" align="center">
						<template slot-scope="scope">
							<span>
								{{scope.row.old_money}}
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="" label="余额+" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.change_type=='IN'">
								+{{scope.row.money}}
							</span>
							<span v-if="scope.row.change_type=='OUT'">
								0
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="" label="余额-" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.change_type=='OUT'">
								-{{scope.row.money}}
							</span>
							<span v-if="scope.row.change_type=='IN'">
								0
							</span>
						</template>
					</el-table-column>
					
					<el-table-column prop="" label="变动后余额" align="center">
						<template slot-scope="scope">
							<span>
								{{scope.row.new_money}}
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="" label="币种" align="center">
						<template slot-scope="scope">
							<span>
								{{scope.row.cur_type}}
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="" label="时间" align="center" width="150">
						<template slot-scope="scope">													
							{{scope.row.add_time}}
						</template>
					</el-table-column>
			  	</el-table>
				
				<div class="flexX  mt20">
					<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				</div>
			</el-drawer>
		</div>
		
		
		
		<!-- 所有额度变动日志 -->
		<el-dialog class="dialog" top="20px" center title="总体日志" :visible.sync="allLogdialog" @close="allLogclose" :close-on-click-modal="false" width="80%">
			<el-tabs type="border-card" @tab-click="allGetlogData" v-model="logName">
				<!-- <el-tab-pane label="余额变动" name='recharge' :disabled="logLoding">
					<div>
					<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantNumber')" v-model="log_payway_type_id" clearable class="mr20" style="width: 200px"></el-input>
					
					<el-select v-model="log_type" placeholder="请选择类型" size="mini" class="mr20" clearable>
						<el-option key="IN" label="增加" value="IN"></el-option>
						<el-option key="OUT" label="减少" value="OUT"></el-option>
					</el-select>
					
						<el-button size="mini" plain class="mr20" @click="logListsetDate(-1)" :disabled="cardListdisLoading">前一天</el-button>
						<el-date-picker size="mini" value-format="yyyy-MM-dd" v-model="cardListdate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker>
						<el-button size="mini" plain class="mr20" @click="logListsetDate(1)" :disabled="cardListdisLoading">后一天</el-button>
						<el-button size="mini" type="primary" :icon="cardListdisLoading?'el-icon-loading':'el-icon-search'" :disabled="cardListdisLoading" @click="logListgetData">搜索</el-button>
						
					</div>
						 
					<el-table size="mini" :data="allRechargeLog" border=""  max-height="500px"  :row-class-name="tableLogRowClassName">
						<el-table-column label="通道ID" align="center">
							<template slot-scope='scope'>
								<div>
									{{scope.row.payway_type_id}}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="通道" align="center">
							<template slot-scope='scope'> 
								{{scope.row.payway_name}}
							</template>
						</el-table-column>
						<el-table-column label="操作前金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作金额" align="center">
							<template slot-scope='scope'>
								<div v-if="scope.row.name=='增加'" class="csuccess">{{scope.row.money}}</div>
								<div v-else-if="scope.row.name=='扣除'" class="cdanger">{{scope.row.money}}</div>
								<div v-else class="cwarning">{{scope.row.money}}</div>
								
							</template>
						</el-table-column>
						<el-table-column label="操作后金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.new_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column show-overflow-tooltip prop="note" label="备注" align="center"></el-table-column>
						<el-table-column prop="auditor" label="操作人" align="center"></el-table-column>
						<el-table-column prop="time" label="操作时间" align="center"></el-table-column>
					
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="allReLoghandleSizeChange" @current-change="allReLoghandleCurrentChange"
						 :current-page="allReLogpage" :page-sizes="allReLogpageSizesArr" :page-size="allReLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="allReLogtotal"></el-pagination>
					</div>
				</el-tab-pane> -->
				<el-tab-pane label="预付变更" name='yufu' :disabled="logLoding">
					<div>
						<!-- <el-cascader v-model="yufulog_payway_type_id" class="mr20 mb20" :show-all-levels="false" placeholder="通道" size="mini" :options="tableData" :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="paywayChang" ></el-cascader> -->
						<el-select clearable  class="mr10" size="mini" v-model="yufulog_payway_type_id" placeholder="请选择通道">
						    <el-option
						      v-for="item in tableData"
						      :key="item.id"
						      :label="item.name"
						      :value="item.id">
						    </el-option>
						  </el-select>
					<!-- <el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantNumber')" v-model="yufulog_payway_type_id" clearable class="mr20" style="width: 200px"></el-input> -->
					
					<el-select v-model="log_type" placeholder="请选择类型" size="mini" class="mr20" clearable>
						<el-option key="IN" label="增加" value="IN"></el-option>
						<el-option key="OUT" label="减少" value="OUT"></el-option>
					</el-select>
					
						<el-button size="mini" plain class="mr20" @click="logListsetDate(-1)" :disabled="cardListdisLoading">前一天</el-button>
						<el-date-picker size="mini" value-format="yyyy-MM-dd" v-model="cardListdate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker>
						<el-button size="mini" plain class="mr20" @click="logListsetDate(1)" :disabled="cardListdisLoading">后一天</el-button>
						<el-button size="mini" type="primary" :icon="cardListdisLoading?'el-icon-loading':'el-icon-search'" :disabled="cardListdisLoading" @click="yufulogListgetData">搜索</el-button>
						
					</div>
						 
					<el-table size="mini" :data="yufuallRechargeLog" border=""  max-height="500px"  :row-class-name="tableLogRowClassName">
						<el-table-column label="通道ID" align="center">
							<template slot-scope='scope'>
								<div>
									{{scope.row.payway_type_id}}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="通道" align="center">
							<template slot-scope='scope'> 
								{{scope.row.payway_name}}
							</template>
						</el-table-column>
						<el-table-column label="操作前金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作金额" align="center">
							<template slot-scope='scope'>
								<div v-if="scope.row.name=='增加'" class="csuccess">{{scope.row.money}}</div>
								<div v-else-if="scope.row.name=='扣除'" class="cdanger">{{scope.row.money}}</div>
								<div v-else class="cwarning">{{scope.row.money}}</div>
								
							</template>
						</el-table-column>
						<el-table-column label="操作后金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.new_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column show-overflow-tooltip prop="note" label="备注" align="center"></el-table-column>
						<el-table-column prop="auditor" label="操作人" align="center"></el-table-column>
						<el-table-column prop="time" label="操作时间" align="center"></el-table-column>
					
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="allReLoghandleSizeChange" @current-change="allReLoghandleCurrentChange"
						 :current-page="yufuallReLogpage" :page-sizes="yufuallReLogpageSizesArr" :page-size="yufuallReLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="yufuallReLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
			
		</el-dialog>
		
		
		<el-dialog center class="dialog" top="50px" title="通道支付绑定" :visible.sync="manualVisible" @close="manualclose" :close-on-click-modal="false" width="90%">
			<!-- <div v-loading="manualloading"> -->
			<div>
				<div>
					<div class="flexX transfer flexBetween">
						<div class="source">
							<div class="sourceData">
								<el-table border :data="manualTableData" size="mini" height="100%">
									<el-table-column label="支付方式" align="center" width="300px">
										<template slot-scope="scope">
											【{{scope.row.id}}】{{scope.row.name}}
										</template>
									</el-table-column>
									<el-table-column label="操作" align="center" width="50px">
										<template slot-scope="scope">
											<el-checkbox @change="addChange(scope.row, 1)" v-model="scope.row.selected" :disabled="manualloading"></el-checkbox>
										</template>
									</el-table-column>
								</el-table>
		
							</div>
							<div class="mt10 manualPage">
								<el-pagination @size-change="manualhandleSizeChange"
									@current-change="manualhandleCurrentChange" :current-page="manualpage"
									:page-sizes="manualpageSizesArr" :page-size="manualpageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="manualtotal">
								</el-pagination>
							</div>
						</div>
						<div class="target">
							<div class="targetData">
								<el-table border :data="manualTableDataChoose" size="mini" height="100%">
									<el-table-column label="支付方式" align="center" width="200px">
										<template slot-scope="scope">
											【{{scope.row.pay_type}}】{{scope.row.pay_type_name}}
										</template>
									</el-table-column>
									<el-table-column label="通道编号" align="center" width="150px">
										<template slot-scope="scope">
											<el-input
											  size="mini"
											  placeholder="请输入通道编号"
											  v-model="scope.row.relation_type"
											  clearable>
											</el-input>
										</template>
									</el-table-column>
									<el-table-column label="开关" align="center">
										<template slot-scope="scope">
											<el-switch :disabled="disLoading" v-model="scope.row.state" class="mr20"></el-switch>
											<span>{{scope.row.state==1?'启用':'停用'}}</span>
										</template>
									</el-table-column>
									
									<el-table-column label="操作" align="center" width="50px">
										<template slot-scope="scope">
											<el-checkbox @change="addChange(scope.row, 2)" v-model="scope.row.selected" :disabled="manualloading"></el-checkbox>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<div class="mt10 manualPage">
								<el-pagination @size-change="manualRighthandleSizeChange" @current-change="manualRighthandleCurrentChange" :current-page="manualRightpage" :page-sizes="manualRightpageSizesArr" :page-size="manualRightpageSize" layout="total, sizes, prev, pager, next, jumper" :total="manualRighttotal">
								</el-pagination>
							</div>
						</div>
					</div>
		
				</div>
				<div class="textCenter mt40">
					<!-- <el-button type="primary" @click="manualclose">取消</el-button> -->
					<el-button type="primary" @click="manualsuer" :disabled="manualloading">确认</el-button>
				</div>
			</div>
		</el-dialog>
		
		
		<el-dialog center class="dialog" top="50px" title="编辑" :visible.sync="editVisible" @close="editClose" :close-on-click-modal="false" width="30%">
			<div class="formBox2"  v-loading="dialogloading">
				<div>
					<el-form size="mini" :label-position="labelPosition" :model="editItem"  label-width="80px">
					  <el-form-item label="名称" >
					    <el-input v-model="editItem.name" autocomplete="off"></el-input>
					  </el-form-item>
					  <el-form-item label="商户号" >
					    <el-input v-model="editItem.payway_business_no" autocomplete="off"></el-input>
					  </el-form-item>
					  <el-form-item label="秘钥" >
					    <el-input v-model="editItem.payway_key" autocomplete="off"></el-input>
					  </el-form-item>
					  <el-form-item label="支付下单" >
					    <el-input v-model="editItem.payway_pay_req_url" autocomplete="off"></el-input>
					  </el-form-item>
					  <el-form-item label="支付查询" >
					    <el-input v-model="editItem.payway_pay_order_url" autocomplete="off"></el-input>
					  </el-form-item>
					  <el-form-item label="代付下单" >
					    <el-input v-model="editItem.payway_repay_req_url" autocomplete="off"></el-input>
					  </el-form-item>
					  <el-form-item label="代付查询" >
					    <el-input v-model="editItem.payway_repay_order_url" autocomplete="off"></el-input>
					  </el-form-item>
					  <el-form-item label="权重" >
					    <el-input v-model="editItem.weight" autocomplete="off"></el-input>
					  </el-form-item>
					  <el-form-item label="回调ip" >
						<el-tag
							v-if="ipTags.length>0"
						 type="success"
						   class="mr10"
						  :key="tag"
						  v-for="tag in ipTags"
						  closable
						  :disable-transitions="false"
						  @close="taghandleClose(tag)">
						  {{tag}}
						</el-tag>
						<el-input
						  class="input-new-tag mr10"
						  v-if="inputVisible"
						  v-model="inputValue"
						  ref="saveTagInput"
						  size="mini"
						  @keyup.enter.native="taghandleInputConfirm"
						  @blur="taghandleInputConfirm"
						>
						</el-input>
						<el-button v-else class="button-new-tag" size="small" @click="tagshowInput">+</el-button>
					  </el-form-item>
					</el-form>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
			  <el-button @click="editVisible = false">{{$t('main.common.cancel')}}</el-button>
			  <el-button type="primary" @click='editSure()'>{{$t('main.common.submit')}}</el-button>
			</div>
		</el-dialog>
		
		<!-- 通道结算明细 -->
		<el-dialog title="结算明细" width="75%" center :visible.sync="jsDialog"  @close="jsClose" :close-on-click-modal="false" >
			<div class="flexX flexBetween mb10" >
				<div>
					<div class="flexY mb10">
						<div>
							通道名称：<span>{{this.jsmxItem.name}}</span>
						</div>
						
					</div>
					<el-table :data="this.jsmxData" border size="mini">
					  <el-table-column property="add_date" label="日期"></el-table-column>
					  <el-table-column label="状态">
						  <template slot-scope='scope'>
						  		<span class="csuccess" v-if="scope.row.state == 1">已结算</span>
						  		<span class="cdanger" v-else>未结算</span>
						  </template>
					  </el-table-column>
					  <el-table-column property="shouldSettleMoney" label="应结算"></el-table-column>
					  <el-table-column property="alreadySettleMoney" label="已结算"></el-table-column>
					  <el-table-column property="notSettleMoney" label="未结算"></el-table-column>
					  <el-table-column property="totalPaywayInMoney" label="成功金额"></el-table-column>
					  <el-table-column property="totalPaywayEntryMoney" label="入账金额"></el-table-column>
					  <el-table-column property="totalPayMoney" label="调余额"></el-table-column>
					  <el-table-column property="totalRepaidMoney" label="预付"></el-table-column>
					  <el-table-column  label="操作">
						  <template slot-scope='scope'>
						  		<el-link class="mr10" type="primary" @click="jiesuan(scope.row, 'mx')">结算详情</el-link>
						  </template>
						  
					  </el-table-column>
					</el-table>
				</div>
				
			</div>
			<div class="flexX  mt20">
				<el-pagination :hide-on-single-page="true" @size-change="jsSizeChange" @current-change="jsCurrentChange"
				 :current-page="jspage" :page-sizes="jspageSizesArr" :page-size="jspageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="jstotal"></el-pagination>
			</div>
		  
		</el-dialog>
		
		<!--------------------------------- 抽屉操作 END -------------------------------------->
		<rechargeDialog v-if="rechargeDialogDia.show" :type="rechargeDialogDia.type" :show.sync="rechargeDialogDia.show" :id="rechargeDialogDia.id" />
		<paywaySetDialogDia v-if="paywaySetDialogDia.show" :type="paywaySetDialogDia.type" :show.sync="paywaySetDialogDia.show" :id="paywaySetDialogDia.id" />
		<commissionChargeDialog v-if="commissionChargeDia.show"  :show.sync="commissionChargeDia.show" :id="commissionChargeDia.id" />
	</div>
</template>

<script>
	import local from '@/util/local'
	import rechargeDialog from './rechargeDialog.vue'
	import commissionChargeDialog from './commissionCharge.vue'
	import paywaySetDialogDia from './paywaySetDialog.vue'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMoney3,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogVisible:false,
				disLoading: false,
				rechargeDialogDia:{
					id:'',
					show:false,
					type:'recharge'
				},
				commissionChargeDia:{
					id:'',
					show:false
				},
				paywaySetDialogDia:{
					id:'',
					show:false,
					type:'paywaySet'
				},
				title:'',
				date: '',
				input: '',
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				agent: '',
				trader: '',
				spanArr: [],
				pos: '',
				item:{},
				feeList:[],
				type:'',
				height:0,
				innerVisible: false, //内层弹框
				innerDialogTitle: '', //内层弹框
				dialogInnerVisible: false, //内层弹框
				agentTraderFeeList: [], //手续费商家列表
				payWayFee:{},
				feeType:'',
				dwitem:{},
				activeIndex: 'fee',
				dwdialog: false,
				dialogloading: false,
				labelPosition: 'right',
				tableList: [],
				submitText: '确定',
				spanArrFee: [],
				repayTableList: [],
				// 增减通道授信额度.
				unmoneyRadio: 'IN',
				dfunmoneyRadio: 'DF',
				dfRadio: 'DF',
				unmoneyAmount: '',
				unmoneyNote: '',
				//查询
				pay_type: '',
				repay_type: '',
				payTypeArr: [],
				repayTypeArr: [],
				// 余额明细.
				balanceList: [],
				balanceDrawer: false,
				paywayTypeId: '',
				// 通道调额
				teDialog: false,
				paywayItem: [],
				amountForm: {
					amount:'',
					amountNote: '',
					googleCode:'',
				},
				teType: '',
				teTitle: '',
				// 结算.
				jiesuanDialog: false,
				jsItem: [],
				jsData: [],
				// 商户所有余额操作日志.
				allLogdialog: false,
				allRechargeLog: [],
				log_payway_type_id: '',
				log_type:'',
				allReLogpageSizesArr:[50, 100, 200],
				allReLogpageSize:50,
				allReLogtotal:0,
				allReLogpage:1,
				
				// 商户预付操作日志.
				yufulog_payway_type_id:'',
				yufuallLogdialog: false,
				yufuallRechargeLog: [],
				yufulog_type:'',
				yufuallReLogpageSizesArr:[50, 100, 200],
				yufuallReLogpageSize:50,
				yufuallReLogtotal:0,
				yufuallReLogpage:1,
				//Log 日志
				logName: 'yufu',
				logChangeType: 'yufu',
				logdialog: false,
				logLoding: false,
				cardListdate:null,
				cardListdisLoading:false,
				// 通道绑定支付方式
				manualVisible: false,
				manualQueryloading: false,
				manualloading: false,
				manualTableData: [],
				manualTableDataChoose: [],
				manualpageSizesArr: [20, 50, 100, 200, 5000],
				manualpageSize: 20,
				manualtotal: 0,
				manualpage: 1,
				canAdd: false,
				canCancle: false,
				//右侧
				manualRightpageSizesArr: [20, 50, 100, 200, 5000],
				manualRightpageSize: 20,
				manualRighttotal: 0,
				manualRightpage: 1,
				// 编辑
				editVisible: false,
				editItem: [],
				ipTags:[],
				inputVisible: false,
				inputValue: '',
				// 通道固额，范围设置
				fitoptions:[{
					  value: 1,
					  label: '范围'
					}, {
					  value: 2,
					  label: '固额'
					}
				],
				fittype:1,
				// 结算明细
				jsmxData: [],
				jsmxItem: [],
				jsDialog: false,
				jspageSizesArr:[50, 100, 200],
				jspageSize:50,
				jstotal:0,
				jspage:1,
				// 通道绑定类型
				paywayRelationType:'',
				// 一键绑定商户
				cpType: '',
				cpTypes:[
					{
						value: 1,
						label: '代收'
					},
					{
						value: 2,
						label: '代付'
					},
				],
				cpData:'',
				cpDatas:[],
				// 一键配置的商户
				traderChecked:[],
				traderList:[],
			    checkAll: false,
			    isIndeterminate: false,
				// 选择列是否展示
				CheckColumn:[
					{value: 'name',label: '通道名称'},
					{value: 'pay_type_list',label: '代收方式'},
					{value: 'repay_type_list',label: '代付方式'},
					{value: 'weight',label: '权重'},
					{value: 'balance',label: '实时余额'},
					{value: 'pay_money',label: '通道余额'},
					{value: 'repaid_money',label: '代收预付'},
					{value: 'remaind_repaid',label: '净预付'},
					{value: 'credit_money',label: '代收信用额度'},
					{value: 'df_credit_money',label: '代付余额'},
					{value: 'stateB',label: '总开关'},
					{value: 'pay_stateB',label: '代收开关'},
					{value: 'repay_stateB',label: '代付开关'},
					{value: 'operation',label: '操作'},
				],
				CheckedCol:[],
				
				
				
			}
		},
		components:{rechargeDialog,commissionChargeDialog,paywaySetDialogDia},
		created() {			
			this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)]
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			this.paywayAllList()
			this.payTypeAll()
			this.repayTypeAll()
			
			if(local.get('paywayCol')){
				this.CheckedCol = local.get('paywayCol')
			}else{
				this.CheckedCol = this.CheckColumn.map(column => column.value);
			}
			// this.$nextTick(() => {
			// 	let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
			// 	let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
			// 	let documentHeight = document.documentElement.clientHeight
			// 	this.height = documentHeight - topNav - 20 - pageBox - 20 
			// })
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					// return ((Math.floor((value * 1)*100)/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			},
			tofixed_two: function(value) {
				if (value || value == 0) {
					let val =Math.floor(((value * 1)*10000).toPrecision(12))
					return ((val/10000).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			colChange(val){
				local.set('paywayCol', val)
			},
			handleCheckAllChange(val) {
			  this.traderChecked = val ? this.traderList.map(item => item.business_no) : [];
			  this.isIndeterminate = false;
			},
			handleCheckedChange(value) {
			  let checkedCount = value.length;
			  this.checkAll = checkedCount === this.traderList.length;
			  this.isIndeterminate = checkedCount > 0 && checkedCount < this.traderList.length;
			},
			getBindList(pay_type){
				let data = {
					'id': this.dwitem.id,
					'type': this.cpType,
					'pay_type': pay_type
				}
				this.logLoding = true;
				this.$api.traderPayType(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.traderList = res.data
					    this.traderChecked = this.traderList.filter(item => item.is_bind === 1).map(item => item.business_no);
					    // 设置全选和部分选中状态
					    this.checkAll = this.traderChecked.length === this.traderList.length;
					    this.isIndeterminate = this.traderChecked.length > 0 && this.traderChecked.length < this.traderList.length;
					
						
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			cpDataChange(val){
				this.traderList = []
				this.traderChecked = []
				this.getBindList(val)
			},
			cpChange(val){
				this.cpData = '';
				this.cpDatas = [];
				this.traderList = []
				this.traderChecked = []
				if(val == 1){
					this.cpDatas = this.dwitem.pay_type_list
				}
				if(val == 2){
					this.cpDatas = this.dwitem.repay_type_list
				}
			},
			jsSizeChange(val){
				this.jspage = 1
				this.jspageSize = val
				this.getJsDataList();
			},
			jsCurrentChange(val){
				// console.log(`当前页: ${val}`);
				this.jspage = val
				this.getJsDataList();
			},
			jsmx(item){
				this.jsmxItem = item;
				this.getJsDataList();
				this.jsDialog = true;
			},
			jsClose(){
				this.jsmxItem = [];
				this.jsmxData = []
				this.jsDialog = false;
			},
			getJsDataList(){
				let data = {
					// start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					// end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
					'id': this.jsmxItem.id,
					'page': this.jspage,
					'size': this.jspageSize
				}
				this.logLoding = true;
				this.$api.paywaySettleList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.jsmxData = res.data.data;
						this.jstotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			delPayway(id){
				let data = {
					id: id,
				}
				this.dialogloading = true
				this.$api.deletePayway(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.paywayAllList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			// 编辑保存
			editSure(dataArr) {
				let obj = {
					id: this.editItem.id,
					name: this.editItem.name,
					payway_business_no: this.editItem.payway_business_no,
					payway_key: this.editItem.payway_key,
					payway_pay_req_url: this.editItem.payway_pay_req_url,
					payway_repay_req_url: this.editItem.payway_repay_req_url,
					payway_repay_money_url: this.editItem.payway_repay_money_url,
					payway_repay_order_url: this.editItem.payway_repay_order_url,
					weight: this.editItem.weight,
					payway_pay_order_url: this.editItem.payway_pay_order_url,
					callback_ip: this.ipTags.join('|'),
					
				}
				this.dialogloading = true
				this.$api.editPayway(obj).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.$message({type:'success',message:'编辑成功'})
						this.paywayAllList()
						this.editVisible = false
					}else{
						this.dialogloading = false
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
				

			},
			  taghandleClose(tag) {
				this.ipTags.splice(this.ipTags.indexOf(tag), 1);
			  },
			  tagshowInput() {
				this.inputVisible = true;
				this.$nextTick(_ => {
				  this.$refs.saveTagInput.$refs.input.focus();
				});
			  },
			  taghandleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
				  this.ipTags.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			  },
			      fixhandleClose(tag, row) {
			        const tags = row.fix_gold.split(',');
			        tags.splice(tags.indexOf(tag), 1);
			        row.fix_gold = tags.join(',');
			      },
				  fixshowInput(index) {
					this.tableList[index].inputVisible = true;
					this.$nextTick(() => {
					  const input = this.$refs['saveTagInput' + index];
					  console.log(input)
					  if (input) {
						input.focus();
					  }
					});
				  },
			      fixhandleInputConfirm(row) {
			        if (row.inputValue) {
			          row.fix_gold = row.fix_gold ? `${row.fix_gold},${row.inputValue}` : row.inputValue;
			        }
					console.log(row)
			        row.inputVisible = false;
			        row.inputValue = '';
			      },
			 //  fixhandleClose(tag,item,ix) {
				//   	let fixobj = []
				//   	let fixstr = ''
				//   	fixobj = this.tableList[ix].fix_gold.split(',')
				//     fixobj.splice(fixobj.indexOf(tag), 1);
				//   	fixstr = fixobj.join(',')
				//     this.tableList[ix].fix_gold = fixstr
			 //  },
			 //  fixshowInput() {
				// this.inputVisible = true;
				// this.$nextTick(_ => {
				//   this.$refs.saveTagInput.$refs.input.focus();
				// });
			 //  },
			 //  fixhandleInputConfirm(ix) {
				// let inputValue = this.inputValue;
				// if (inputValue) {
				// 	let fixobj = []
				// 	let fixstr = ''
				// 	if(this.tableList[ix].fix_gold.length>0){
				// 		fixobj = this.tableList[ix].fix_gold.split(',')
				// 	}
					
				//     fixobj.push(inputValue);
				// 	fixstr = fixobj.join(',')
				//   this.tableList[ix].fix_gold = fixstr
				// }
				// this.inputVisible = false;
				// this.inputValue = '';
			 //  },
			
			showEdit(item){
				this.editVisible = true
				this.editItem = item
				this.ipTags = item.callback_ip ? item.callback_ip.split('|') : [];
			},
			editClose(){
				this.editVisible = false
				this.editItem = []
				this.ipTags = []
			},
			paywayChang(val){
				console.log(val)
			},
			// 确认保存编号
			manualsuer(dataArr) {
				// if(this.manualTableDataChoose.length == 0){
				// 	return
				// }
				this.manualTableDataChoose.forEach(item => {
					if(item.state == true){
						item.state = 1
					}
					if(item.state == false){
						item.state = 0
					}
				})
				
				let obj = {
					id: this.item.id,
					data:JSON.stringify(this.manualTableDataChoose)
				}
				this.manualloading = true
				if(this.paywayRelationType == 'pay'){
					this.$api.setRelationType(obj).then(res => {
						this.manualloading = false
						if (res.status == 1) {
							this.$message({type:'success',message:'通道配置成功'})
							// this.manualDisCardList()
							// this.choosedDisCardList()
							// this.paywayAllList()
							this.manualVisible = false
						}else{
							this.manualloading = false
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}
				if(this.paywayRelationType == 'repay'){
					this.$api.setRelationRepayType(obj).then(res => {
						this.manualloading = false
						if (res.status == 1) {
							this.$message({type:'success',message:'通道配置成功'})
							// this.manualDisCardList()
							// this.choosedDisCardList()
							// this.paywayAllList()
							this.manualVisible = false
						}else{
							this.manualloading = false
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}

			},
			manualhandleSizeChange(val) {
				this.manualpageSize = val
				this.manualpage = 1
				this.manualDisCardList()
			},
			manualhandleCurrentChange(val) {
				this.manualpage = val
				this.manualDisCardList();
			},
			manualRighthandleSizeChange(val) {
				this.manualRightpageSize = val
				this.manualRightpage = 1
				this.choosedDisCardList();
			},
			manualRighthandleCurrentChange(val) {
				this.manualRightpage = val
				this.choosedDisCardList()
			},
			//打开绑定通道弹框
			showChooseCard(item, type) {
				this.paywayRelationType = type
				this.manualVisible = true
				this.item = item;
				this.manualDisCardList()
				this.choosedDisCardList()
			},
			//查询右边支付方式
			choosedDisCardList() {
				let data = {
					id: this.item.id,
					page: this.manualRightpage,
					size: this.manualRightpageSize,
				}
				if(this.paywayRelationType == 'pay'){
					this.$api.bindRelationType(data).then(res => {
						this.manualQueryloading = false;
						if (res.status == 1) {
							res.data.data.forEach(item => {
								if(item.state == 1){
									item.state = true
								}
								if(item.state == 0){
									item.state = false
								}
							})
							this.manualTableDataChoose = res.data.data
							this.manualRighttotal = res.data.total
						}
					}).catch(error => {
						this.manualQueryloading = false;
					})
				}
				if(this.paywayRelationType == 'repay'){
					this.$api.bindRelationRepayType(data).then(res => {
						this.manualQueryloading = false;
						if (res.status == 1) {
							res.data.data.forEach(item => {
								if(item.state == 1){
									item.state = true
								}
								if(item.state == 0){
									item.state = false
								}
							})
							this.manualTableDataChoose = res.data.data
							this.manualRighttotal = res.data.total
						}
					}).catch(error => {
						this.manualQueryloading = false;
					})
				}
				
			},
			//查询左边支付方式
			manualDisCardList() {
				let data = {
					id: this.item.id,
					page: this.manualpage,
					size: this.manualpageSize,
				}
				this.manualQueryloading = true;
				
				if(this.paywayRelationType == 'pay'){
					this.$api.canBindRelationType(data).then(res => {
						this.manualQueryloading = false;
						if (res.status == 1) {
							res.data.data.forEach(item => {
								item.payAmount = ''
								item.selected = false;
								for (let i = 0; i < this.manualTableDataChoose.length; i++) {
									if (item.id == this.manualTableDataChoose[i].id) {
										item.selected = true;
										item.payAmount = this.manualTableDataChoose[i].payAmount
									}
								}

							})
							this.manualTableData = res.data.data.filter(item => {
								if (!item.selected) {
									return item
								}
							});
							this.manualtotal = res.data.total
						}
					}).catch(error => {
						this.manualQueryloading = false;
					})
				}
				if(this.paywayRelationType == 'repay'){
					this.$api.canBindRelationRepayType(data).then(res => {
						this.manualQueryloading = false;
						if (res.status == 1) {
							res.data.data.forEach(item => {
								item.payAmount = ''
								item.selected = false;
								for (let i = 0; i < this.manualTableDataChoose.length; i++) {
									if (item.id == this.manualTableDataChoose[i].id) {
										item.selected = true;
										item.payAmount = this.manualTableDataChoose[i].payAmount
									}
								}

							})
							this.manualTableData = res.data.data.filter(item => {
								if (!item.selected) {
									return item
								}
							});
							this.manualtotal = res.data.total
						}
					}).catch(error => {
						this.manualQueryloading = false;
					})
				}
			},
			addChange(item, type) {
				let pay_type = ''
				if(type == 1){
					pay_type = item.id
				}
				if(type == 2){
					pay_type = item.pay_type
				}
				let data = {
					id: this.item.id,
					pay_type: pay_type,
					type: type,
				}
				
				this.manualloading = true
				if(this.paywayRelationType == 'pay'){
					this.$api.editRelationType(data).then(res => {
						this.manualloading = false
						if (res.status == 1) {
							this.manualDisCardList()
							this.choosedDisCardList()
						} else {
							item.selected = false
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.manualloading = false
						item.selected = false
					})
				}
				if(this.paywayRelationType == 'repay'){
					this.$api.editRelationRepayType(data).then(res => {
						this.manualloading = false
						if (res.status == 1) {
							this.manualDisCardList()
							this.choosedDisCardList()
						} else {
							item.selected = false
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.manualloading = false
						item.selected = false
					})
				}	
			},
			manualclose() {
				this.paywayRelationType = ''
				this.manualVisible = false;
				this.manualloading = false;
				this.manualQueryloading = false;
				this.canAdd = false;
				this.canCancle = false;
				this.manualTableData = [];
				this.manualTableDataChoose = [];
				this.manualtotal = 0;
				this.manualpage = 1;
				this.manualRighttotal = 0;
				this.manualRightpage = 1;
				this.item = {}
				this.paywayAllList()
			},
			logListsetDate(day) {
				let data = this.cardListdate?this.cardListdate[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.cardListdate = [newDate, newDate]
				
				if(this.logChangeType == 'recharge'){
					this.logListgetData();
				}
				if(this.logChangeType == 'yufu'){
					this.yufulogListgetData();
				}
				// this.logListgetData()
				// console.log(this.date)
			},
			allReLoghandleSizeChange(val){
				this.allReLogpage = 1
				this.allReLogpageSize = val
				this.allLogList();
			},
			allReLoghandleCurrentChange(val){
				// console.log(`当前页: ${val}`);
				this.allReLogpage = val
				this.allLogList();
			},
			yufuallReLoghandleSizeChange(val){
				this.yufuallReLogpage = 1
				this.yufuallReLogpageSize = val
				this.yufuallLogList();
			},
			yufuallReLoghandleCurrentChange(val){
				// console.log(`当前页: ${val}`);
				this.yufuallReLogpage = val
				this.yufuallLogList();
			},
			tableLogRowClassName({row, rowIndex}) {
				if (row.change_type === 'IN') {
					return 'Log-in-row';
				} else if (row.change_type === 'OUT') {
					return 'Log-out-row';
				}
				return '';
			},
			logListgetData(){
				this.diapage = 1;
				
				this.allLogList();
			},
			yufulogListgetData(){
				this.diapage = 1;
				
				this.yufuallLogList();
			},
			allGetlogData(val){
				this.logChangeType = val.name
				this.allRechargeLog = []
				this.yufuallRechargeLog = []
				if(this.logChangeType == 'recharge'){
					this.allLogList();
				}
				if(this.logChangeType == 'yufu'){
					this.yufuallLogList();
				}
			},
			// 总体日志
			showAllLog(){
				this.allLogdialog = true
				this.yufuallLogList();
			},
			allLogclose(){
				this.allLogdialog = false
				this.logName = 'yufu'
				this.allRechargeLog = []
				this.yufuallRechargeLog = []
				this.log_payway_type_id = ''
				this.yufulog_payway_type_id = ''
				this.log_type = ''
			},
			//余额 充值|扣款
			allLogList(){
				let data = {
					page:this.allReLogpage,
					size:this.allReLogpageSize,
					start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
					'payway_type_id': this.log_payway_type_id,
					'type': this.log_type,
				}
				this.logLoding = true;
				this.$api.allLogList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.allRechargeLog = res.data.data;
						this.allReLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			//预付 充值|扣款
			yufuallLogList(){
				let data = {
					page:this.allReLogpage,
					size:this.allReLogpageSize,
					start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
					'payway_type_id': this.yufulog_payway_type_id,
					'type': this.log_type,
				}
				this.logLoding = true;
				this.$api.paywayMoneyRepaidChangeList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.yufuallRechargeLog = res.data.data;
						this.yufuallReLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			jst(){
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.jsData.id,
					}
					this.dialogloading = true
					this.$api.paywayTypeSettle(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.getJsData()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消结算`
					});
				});
			},
			jiesuan(item, type){
				this.jsItem = item;
				if(type == 'mx'){
					this.cardListdate = [item.add_date+' 00:00:00', item.add_date+' 23:59:59']
					this.jsItem = this.jsmxItem
				}
				this.getJsData();
				this.jiesuanDialog = true;
			},
			jiesuanClose(){
				this.jsItem = [];
				this.jsData = []
				this.jiesuanDialog = false;
			},
			getJsData(){
				let data = {
					start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
					'id': this.jsItem.id,
				}
				this.logLoding = true;
				this.$api.paywaySettleDetail(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.jsData = res.data;
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			openDia(type, item){
				this.teDialog = true
				this.paywayItem = item
				this.teType = type
				if(type == 'tdye'){
					this.teTitle = [item.name] + '-通道余额调额';
				}
				if(type == 'dsyf'){
					this.teTitle = [item.name] + '-代收预付调额';
				}
				if(type == 'dsxyed'){
					this.teTitle = [item.name] + '-代收信用调额';
				}
				if(type == 'dfxyed'){
					this.teTitle = [item.name] + '-代付信用调额';
				}
				
			},
			teClose(){
				this.teDialog = false
				this.paywayItem = []
				this.amountForm.amount = ''
				this.amountForm.amountNote = ''
				this.amountForm.googleCode = ''
				this.teType = false
			},
			getBalanceData(){
				this.balanceChangelist()
			},
			// 关闭通道余额明细列表
			balanceHandleClose() {
				this.paywayTypeId = ''
				this.balanceDrawer = false
				this.balanceList = []
			},
			// 查看通道余额明细列表
			CheckBalance(id) {
				this.balanceDrawer = true
				this.paywayTypeId = id
				this.balanceChangelist()
			},
			// 查看通道余额明细列表
			balanceChangelist() {
				this.balanceDrawer = true
				let data = {
					id: this.paywayTypeId,
					page: this.page,
					size: this.pageSize,
					startdate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					enddate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					
				}
				// this.loading = true
				this.$api.balanceChangelist(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.balanceList = res.data.data
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
				})
			},
			// 代收方式列表
			payTypeAll() {
				let data = {}
				// this.loading = true
				this.$api.payTypeAll(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.payTypeArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
				})
			},
			// 代付方式列表
			repayTypeAll() {
				let data = {}
				// this.loading = true
				this.$api.repayTypeAll(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.repayTypeArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
				})
			},
			setDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			setBalanceDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getBalanceData()
			},
			closeDia(){
				this.rechargeDialogDia.show = false
				this.paywaySetDialogDia.show = false
			},
			//查看列表
			paywayAllList() {
				let data = {
					'pay_type': this.pay_type,
					'repay_type': this.repay_type,
				}
				this.disLoading = true
				this.$api.paywayAllList(data).then(res => {
					this.loading = false
					this.disLoading = false
					if (res.status == 1) {
						res.data.data.forEach(item=>{
							if(item.state == 0){
								item.stateB = true;
							}else{
								item.stateB = false;
							}
							if(item.pay_state == 1){
								item.pay_stateB = true;
							}else{
								item.pay_stateB = false;
							}
							if(item.repay_state == 1){
								item.repay_stateB = true;
							}else{
								item.repay_stateB = false;
							}
							// 初始化 balance 字段
							this.$set(item, 'balance', null);
							this.$set(item, 'yeloading', false);
						})
						  
						this.tableData = res.data.data
						
						// 获取每条数据的余额
						  this.tableData.forEach(item => {
							this.getBalance(item.id);
						  });
						
						this.spanArr= []
						this.pos= ''
						this.getSpanArr(this.tableData)
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			// 获取余额并更新列表
			getBalance(id, cz = 0) {
			  const index = this.tableData.findIndex(item => item.id === id);
			  if (index === -1) return;
			  
			  // 设置 loading 状态
			  this.$set(this.tableData[index], 'yeloading', true);
			  
			  this.$api.queryBanalce({'id':id}).then(res => {
				this.$set(this.tableData[index], 'yeloading', false);
			    if (res.status == 1) {
			      const balance = res.data
			      const index = this.tableData.findIndex(item => item.id === id);
			      if (index !== -1) {
			        this.$set(this.tableData, index, { ...this.tableData[index], balance });
			      }
				  if(cz == 1){
					  this.$message({
					  	type: 'success',
					  	message: `实时余额更新成功【${balance}】`
					  })
				  }
			    } else {
			      console.error(`Error fetching balance for ID ${id}:`, res.msg);
			    }
			  }).catch(error => {
			  this.$set(this.tableData[index], 'yeloading', false);
			    console.error(`Error fetching balance for ID ${id}:`, error);
			  });
			  this.$set(this.tableData[index], 'yeloading', false);
			},
			getSpanArr(data) {
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						this.spanArr.push(1);
						this.pos = 0
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i].id === data[i - 1].id) {
							this.spanArr[this.pos] += 1;
							this.spanArr.push(0);
						} else {
							this.spanArr.push(1);
							this.pos = i;
						}
					}
					
				}
			},
			objectSpanMethod({row,column,rowIndex,columnIndex}) {
				if (columnIndex === 0||columnIndex === 4||columnIndex === 5||columnIndex === 6||columnIndex === 2||columnIndex === 3) {
					const _row = this.spanArr[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					// console.log(`rowspan:${_row} colspan:${_col}`)
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
            objectSpanMethod2({row,column,rowIndex,columnIndex}) {
				// console.log(this.spanArrFee)
				if (columnIndex === 0) {
					const _row = this.spanArrFee[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
            getSpanArrFee(data) {
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						this.spanArrFee.push(1);
						this.pos = 0;
						
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i].pay_type === data[i - 1].pay_type) {
							this.spanArrFee[this.pos] += 1;
							this.spanArrFee.push(0);
						} else {
							this.spanArrFee.push(1);
							this.pos = i;
						}
					}
				}
            },
			showDialog(type,item){
				this.type = type
				this.item = item
				if(type == 'fee'){
					this.title = '设置手续费'
					this.paywayfeeList(0)
					this.getFeeNum()
				}
				if(type == 'gold'){
					this.title = this.$t('main.passagewayMenu.setLimits')
					this.paywayfeeList(1)
				}
				if(type == 'recharge' || type == 'limit'){
					this.rechargeDialogDia.id = item.id
					this.rechargeDialogDia.show = true
					this.rechargeDialogDia.type = type
				}else if(type == 'charge'){
					this.commissionChargeDia.id = item.id
					this.commissionChargeDia.show = true
				}else if(type == 'paywaySet'){
					this.paywaySetDialogDia.id = item.id
					this.paywaySetDialogDia.show = true
				}
			},
			paywayfeeList(show_repay){
				let data = {
					has_repay:show_repay,
					id:this.item.id
				}
				this.$api.paywayfeeList(data).then(res=>{
					if(res.status == 1){
						this.feeList = res.data
					}else{
						this.$message({type:"error",message:res.msg})
					}
				})
			},
			onSubmit(){
				// 代收手续费.
				if(this.type == 'fee'){
					if(this.tableList.length == 0){
						return
					}
					this.bottonLoading = true
					let obj = {
						id:this.dwitem.id,
						data:JSON.stringify(this.tableList)
					}
					
					this.dialogloading = true
					this.$api.setFeeSaveAdd(obj).then(res => {
						
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							
							this.getFeeList(this.dwitem, 'fee')
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						
						this.dialogloading = false
					})
				}
				// 代付手续费.
				if(this.type == 'reFee'){
					if(this.repayTableList.length == 0){
						return
					}
					let obj = {
						id:this.dwitem.id,
						data:JSON.stringify(this.repayTableList)
					}
					
					this.dialogloading = true
					this.$api.getSetRepayFee(obj).then(res => {
						
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.getRepayFeeList(this.dwitem, 'fee')
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						
						this.dialogloading = false
					})
				}
				// 通道余额.
				if(this.teType == 'tdye'){
					let obj = {
						id:this.paywayItem.id,
						amount: this.amountForm.amount,
						gold_type: 'TDYE',
						note: this.amountForm.amountNote,
						googleCode: this.amountForm.googleCode,
					}
					
					this.dialogloading = true
					this.$api.setPaywayCreditMoney(obj).then(res => {
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.paywayAllList()
							this.teClose()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						this.dialogloading = false
					}).catch(error => {
						this.dialogloading = false
						this.paywayAllList()
					})
				}
				// 代收信用额度调额.
				if(this.teType == 'dsxyed'){
					let obj = {
						id:this.paywayItem.id,
						amount: this.amountForm.amount,
						gold_type: 'DS',
						note: this.amountForm.amountNote,
						googleCode: this.amountForm.googleCode,
					}
					
					this.dialogloading = true
					this.$api.setPaywayCreditMoney(obj).then(res => {
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.paywayAllList()
							this.teClose()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						this.dialogloading = false
					}).catch(error => {
						this.dialogloading = false
						this.paywayAllList()
					})
				}
				// 代付信用额度调额.
				if(this.teType == 'dfxyed'){
					let obj = {
						id:this.paywayItem.id,
						amount: this.amountForm.amount,
						gold_type: 'DF',
						note: this.amountForm.amountNote,
						googleCode: this.amountForm.googleCode,
					}
					
					this.dialogloading = true
					this.$api.setPaywayCreditMoney(obj).then(res => {
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.paywayAllList()
							this.teClose()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						this.dialogloading = false
					}).catch(error => {
						this.dialogloading = false
						this.paywayAllList()
					})
				}
				// 代付信用额度调额.
				if(this.teType == 'dsyufu'){
					let obj = {
						id:this.paywayItem.id,
						amount: this.amountForm.amount,
						gold_type: 'DSYF',
						note: this.amountForm.amountNote,
						googleCode: this.amountForm.googleCode,
					}
					
					this.dialogloading = true
					this.$api.setPaywayCreditMoney(obj).then(res => {
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.paywayAllList()
							this.teClose()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						this.dialogloading = false
					}).catch(error => {
						this.dialogloading = false
						this.paywayAllList()
					})
				}
				// 通道一键绑定商户.
				if(this.type == 'configTra'){
					
					let data = this.traderList.map(item => ({
					        business_no: item.business_no,
					        is_bind: this.traderChecked.includes(item.business_no) ? "1" : "0"
					      }));
					data = JSON.stringify(data);
					let obj = {
						id:this.dwitem.id,
						type: this.cpType,
						pay_type: this.cpData,
						data: data,
					}
					
					this.dialogloading = true
					this.$api.bindTraderPayType(obj).then(res => {
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.getBindList(this.cpData)
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						this.dialogloading = false
					}).catch(error => {
						this.dialogloading = false
						this.paywayAllList()
					})
				}
				
			},
			close(){
				this.dialogVisible = false;
				this.item = {}
				this.title=''
				this.feeList=[]
				this.type = ''
			},
			getData() {
				this.page = 1;
				this.paywayAllList()
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.balanceChangelist()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.balanceChangelist()
			},
			setStatePayway(item){
				let data = {
					id:item.id,
					state:item.stateB?"0":"1",
				}
				this.disLoading = true;
				this.$api.setStatePayway(data).then(res=>{
					this.disLoading = false;
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.paywayAllList()
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.disLoading = false;
					this.paywayAllList()
				})
			},
			setPayState(item){
				let data = {
					id:item.id,
					state:item.pay_stateB?"1":"0",
				}
				this.disLoading = true;
				this.$api.setPayState(data).then(res=>{
					this.disLoading = false;
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.paywayAllList()
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.disLoading = false
					this.paywayAllList()
				})
			},
			setRepayState(item){
				let data = {
					id:item.id,
					state:item.repay_stateB?"1":"0",
				}
				this.disLoading = true;
				this.$api.setRepayState(data).then(res=>{
					this.disLoading = false;
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.paywayAllList()
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.disLoading = false
					this.paywayAllList()
				})
			},
			//内层弹框关闭
			closeInner() {
				this.innerVisible = false
				this.innerDialogTitle = ''
				this.agentTraderFeeList = []
				this.feeType  = ''
			},
			paywayTypeAgentFeeList(item,type) {
				this.feeType = type
				this.minFeeList = false;
				this.innerVisible = true
				if(type == "sigle"){
					this.innerDialogTitle = `代理对于${this.item.name}的单笔手续费`
				}else{
					this.innerDialogTitle = '代理对于'+item.name + '的费率'
				}
				let data = {}
				if(type == "sigle"){
					data = {
						payway_type_id: this.item.id,
						pay_type: 88
					}
				}else{
					data = {
						payway_type_id: this.item.id,
						pay_type: item.id
					}
				}
				this.dialogInnerVisible = true
				this.$api.paywayTypeAgentFeeList(data).then(res => {
					this.dialogInnerVisible = false
					if (res.status === 1) {
						this.agentTraderFeeList = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogInnerVisible = false
				})
			},
			getFeeNum(){
				let data = {
					id:this.item.id
				}
				this.$api.getFeeNum(data).then(res => {
					if (res.status === 1) {
						this.payWayFee = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
			setFeeNum(){
				let data = {
					id:this.item.id,
					fee_num:this.payWayFee.fee_num
				}
				this.$api.setFeeNum(data).then(res => {
					if (res.status === 1) {
						this.$message({
							type: "success",
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
			getFeeList(item, type){
				let data = {
					id:item.id
				}
				
				this.loading = true
				this.$api.getFeeList(data).then(res => {
				    this.loading = false
				    if (res.status == 1) {
				        // 组装数据
				        let newFeeData = [];
				        // console.log('res.data',res.data);
				        // this.tableList = res.data
						this.tableList = res.data.map(row => ({
						          ...row,
						          inputVisible: false,
						          inputValue: '',
						        }));
						// this.spanArrFee = []
						// this.pos= ''
				  //       this.getSpanArrFee(this.tableList)
						// console.log(this.tableList)
				    }
				})
			},
			getRepayFeeList(item, type){
				let data = {
					id:item.id
				}
				
				this.loading = true
				this.$api.getRepayFeeList(data).then(res => {
				    this.loading = false
				    if (res.status == 1) {
				        // 组装数据
				        let newFeeData = [];
				        this.repayTableList = res.data
						// this.spanArrFee = []
						// this.pos= ''
				  //       this.getSpanArrFee(this.repayTableList)
						// console.log(this.repayTableList)
				    }
				})
			},
			openDrawer(item){ // 打开抽屉.
				this.dwdialog = true
				this.loading = true
				this.dwitem = item
				this.activeIndex = 'fee'
				if(this.activeIndex == 'fee'){
					this.getFeeList(item, 'fee')
				}
				this.type = this.activeIndex
			},
			// 关闭抽屉
			handleDrawerClosed() {
				this.cpType = ''
				this.cpData = '';
				this.cpDatas = [];
				this.traderList = []
				this.traderChecked = []
				this.isIndeterminate = false
			},
			handleClose(done) {
				console.log('handleClose 触发了')
			    this.close()
				
				this.tableList = []
				this.repayTableList = []
				 //  if (this.dwloading) {
					// return;
				 //  }
				done()
			  
			},
			  handleSelect(key, keyPath) {
				  
				this.tableList = []
				this.repayTableList = []
				
				this.activeIndex = key
				this.type = this.activeIndex
				if(key === 'fee'){
					this.getFeeList(this.dwitem, 'fee')
				}
				if(key === 'reFee'){
					this.getRepayFeeList(this.dwitem, 'fee')
				}
				if(key === 'credit'){
					console.log(this.dwitem)
				}
			  }
		},
		watch: {
			total(val, old) {
				// this.$nextTick(() => {
				// 	let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// 	let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				// 	let documentHeight = document.documentElement.clientHeight
				// 	this.height = documentHeight - topNav - 20 - pageBox - 20
				// })
			}
		},
	}
</script>


<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';

	.transfer {
		height: 50vh;

		// width: 80%;
		.target,
		.source {
			border: solid 1px $tableBorderColor;
			width: 45%;
			// padding: 0 10px;
			height: 100%;

			.sourceData {
				height: calc(100% - 42px);
			}

			.goldBox {
				height: 25px;
				line-height: 25px;
			}

			.targetData {
				height: calc(100% - 25px);
			}

			.manualPage {
				height: 32px;
			}
		}
	}
</style>
<style>

	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	.formBox2 {
		width: 95%;
		margin: 0 auto;
	}
	.textCenter{
		margin-top: 30px
	}
   .demo-drawer__content{
	   margin: 30px 80px 30px;
   }
   
   .el-input {
	   /* margin-left: 10px;
       width: 60%; */
    }
   .el-menu-demo{
	   margin-bottom: 40px;
   }
  .text {
    font-size: 14px;
  }

  .item {
    padding: 1px 0;
  }

  .box-card {
    width: 100%;
	border: 0px;
  }
  
  .custom-select {
    width: 80px; /* 固定下拉框的宽度 */
  }
  
  .custom-select .el-input__inner {
    padding-right: 25px; /* 调整内边距以使箭头正确对齐 */
    height: 30px; /* 固定输入框的高度 */
    line-height: 30px; /* 确保文本在输入框内垂直居中 */
  }
  
  .custom-select .el-input__icon {
    right: 10px; /* 调整箭头位置 */
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px; /* 调整箭头图标的大小 */
    color: #909399; /* 设置箭头图标的颜色 */
  }
  
  .custom-select .el-select__caret {
    font-size: 12px; /* 确保箭头图标的大小固定 */
  }
  
  .drawer-content {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    padding: 20px;
  }
  
  .drawer-footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 50px);
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
  }
</style>
