<template>
    <el-dialog center width="60%" class="dialog" top="150px" :title="type == 'recharge'?$t('main.passagewayMenu.commissionCollection'):$t('main.passagewayMenu.setLimits')" :visible.sync="show" :before-close="close" :close-on-click-modal="false">
        <el-table v-loading="loading" :data="tableList" stripe size="mini"  border  :span-method="objectSpanMethod">
            <el-table-column prop="pay_type_name" :label="$t('main.passagewayMenu.collectionMethod')" widty="50px"  align="center"></el-table-column>
            <el-table-column  :label="type == 'recharge'?$t('main.passagewayMenu.rate')+'(‰)':$t('main.passagewayMenu.singleLimit')" align="center">
                <template slot-scope="scope">
                    <div center style="" v-if="type == 'recharge'">			
                        <table style="float:left; Text-align:left; border:0;">
                            <span>{{scope.row.cur_name}}</span>
                        </table>
                        <table  style="float:right; Text-align:right; border:0;">
                            <el-input-number style="width: 100px;" v-model="scope.row.fee" placeholder="请输入手续费" controls-position="right" class="mr20" :precision="2" :min="parseFloat(scope.row.min_fee)" :max="scope.row.max_fee"></el-input-number>
                            <el-link type="primary" class="mr20" @click="paywayTypeFeeList(scope.row)"> {{$t('main.passagewayMenu.minimum')}}:{{scope.row.min_fee}} </el-link>
                            <el-link type="primary" @click="openInnerDialog(scope.row)"> {{$t('main.passagewayMenu.maximum')}}:{{scope.row.max_fee}}</el-link>
                        </table>
                    </div>
                    <div v-else>
                        <table  style="float:right; Text-align:right; border:0;display:flex;align-items: center;">
                            <span>{{$t('main.passagewayMenu.minimum')}}：</span><el-input-number style="width: 130px;" v-model="scope.row.min_gold" controls-position="right" :placeholder="$t('main.passagewayMenu.minimum')" class="mr20"></el-input-number>
						    <span>{{$t('main.passagewayMenu.maximum')}}：</span><el-input-number style="width: 130px;" v-model="scope.row.max_gold" controls-position="right" :min="scope.row.min_gold" :placeholder="$t('main.passagewayMenu.maximum')" class="mr20"></el-input-number>
                        </table>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="textCenter">
            <el-button type="primary"  v-loading="bottonLoading" @click="onSubmit">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    export default {
        data(){
            return{
                dialogVisible:true,
                tableList:[],
                spanArr:[],
                pos:0,
                loading:false,
                bottonLoading:false
            }
        },
        props:{
            id:{
                type:Number
            },
            show:{
                type:Boolean,
                default:false
            },
            type:{
                type:String,
                default:''
            }
        },
        created(){
            // this.$api.traderFeeList({trader_id:'68'}).then(res => {
            this.loading = true
			let data = {
				has_repay:this.type == 'recharge'?0:1,
				id:this.id
			}
            this.$api.getFeeList(data).then(res => {
                this.loading = false
                if (res.status == 1) {
                    // 组装数据
                    let newFeeData = [];
                    console.log('res.data',res.data);
                    res.data.forEach((item, index)=>{
                        for (let i=0; i<item.cur_list.length;i++) {
                            let current = {
                                    pay_type:item.pay_type,
                                    pay_type_name:item.pay_type_name,
                                    agent_username:item.agent_username,
                                    parentid:item.payway_type_id,
                                    fee:item.cur_list[i].fee,
                                    fee_num:item.cur_list[i].fee_num,
                                    min_fee:item.cur_list[i].min_fee,
                                    max_fee:item.cur_list[i].max_fee,
                                    min_gold:item.cur_list[i].min_gold,
                                    max_gold:item.cur_list[i].max_gold,
                                    cur_id:item.cur_list[i].cur_id,
                                    cur_type:item.cur_list[i].cur_type,
                                    cur_name:item.cur_list[i].cur_name,
                            }
                            newFeeData.push(current)
                        }
                    })
                    this.tableList = newFeeData
                    this.pos= ''
                    this.getSpanArr(this.tableList)
                }
            })
        },
        methods:{
            close(){
                this.$emit('update:show',false)
            },
            getSpanArr(data) {
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						this.spanArr.push(1);
						this.pos = 0;
						
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i].pay_type === data[i - 1].pay_type) {
							this.spanArr[this.pos] += 1;
							this.spanArr.push(0);
						} else {
							this.spanArr.push(1);
							this.pos = i;
						}
					}
				}
            },
            onSubmit(){
                if(this.tableList.length == 0){
                    return
                }
                this.bottonLoading = true
                let obj = {
                    id:this.id,
                    data:JSON.stringify(this.tableList)
                }
                if(this.type == 'recharge'){
                    this.$api.setFeeSaveAdd(obj).then(res => {
                        this.bottonLoading = false
                        if (res.status == 1) {
                            this.$emit('update:show',false)
                        }
                    })
                }else{
                    this.$api.getSetGoldSave(obj).then(res => {
                        this.bottonLoading = false
                        if (res.status == 1) {
                            this.$emit('update:show',false)
                        }
                    })
                }
            },
            objectSpanMethod({row,column,rowIndex,columnIndex}) {
				// console.log(this.spanArr)
				if (columnIndex === 0) {
					const _row = this.spanArr[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
        }
    }
</script>