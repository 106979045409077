<template>
    <el-dialog center width="45%" class="dialog" top="100px" title="配置通道" :visible.sync="show" :before-close="close" :close-on-click-modal="false">
        <el-table v-loading="loading" :data="tableList" stripe size="mini"  border  >
            <el-table-column label="【系统编码】支付方式" widty="50px">
				    <template slot-scope="scope">
						【{{scope.row.pay_type}}】{{scope.row.pay_type_name}}
				    </template>
			</el-table-column>
			<el-table-column label="通道编号" align="center">
                <template slot-scope="scope">
					<el-form :label-position="labelPosition">
						<!-- <el-form-item> -->
							<el-input size="mini" v-model="scope.row.relation_type" placeholder="请输入通道编号"></el-input>
						<!-- </el-form-item> -->
					</el-form>
                </template>
			</el-table-column>
        </el-table>
        <div class="textCenter">
            <el-button type="primary"  v-loading="bottonLoading" @click="onSubmit">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    export default {
        data(){
            return{
                dialogVisible:true,
                tableList:[],
                spanArr:[],
                pos:0,
                loading:false,
                bottonLoading:false,
				labelPosition:'right',
				payway_type_id:0,
				payway_name:'',
            }
        },
        props:{
            id:{
                type:Number
            },
            show:{
                type:Boolean,
                default:false
            },
            type:{
                type:String,
                default:''
            }
        },
        created(){
			this.payway_type_id = this.id
            this.loading = true
			let data = {
				id:this.id
			}
            this.$api.relationTypeList(data).then(res => {
                this.loading = false
                if (res.status == 1) {
                    // 组装数据
                    let newFeeData = [];
                    this.tableList = res.data
                    // this.pos= ''
                    // this.getSpanArr(this.tableList)
                }
            })
        },
        methods:{
            close(){
                this.$emit('update:show',false)
            },
            getSpanArr(data) {
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						this.spanArr.push(1);
						this.pos = 0;
						
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i].pay_type === data[i - 1].pay_type) {
							this.spanArr[this.pos] += 1;
							this.spanArr.push(0);
						} else {
							this.spanArr.push(1);
							this.pos = i;
						}
					}
				}
            },
            onSubmit(){
                if(this.tableList.length == 0){
                    return
                }
				
				let submitData = []
				
                this.bottonLoading = true
                let obj = {
                    id:this.payway_type_id,
                    data:JSON.stringify(this.tableList)
                }
				
				this.$api.setRelationType(obj).then(res => {
					this.bottonLoading = false
					if (res.status == 1) {
						this.$message({type:'success',message:'通道配置成功'})
						this.$emit('update:show',false)
					}else{
						this.bottonLoading = false
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
            },
            objectSpanMethod({row,column,rowIndex,columnIndex}) {
				
				if (columnIndex === 0) {
					const _row = this.spanArr[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
        }
    }
</script>