<template>
    <el-dialog center class="dialog" width="1000px" top="150px" :title="$t('main.passagewayMenu.commissionPayment')" :visible.sync="show" :before-close="close" :close-on-click-modal="false">
        <el-table v-loading="loading" :data="tableList" stripe size="mini"  border >
            <el-table-column prop="cur_name" label="币种" widty="50px"  align="center"></el-table-column>
            <el-table-column :label="$t('main.passagewayMenu.rate')+'(‰)'" align="center">
                <template slot-scope="scope">
                    <div style="display:flex;align-center">
                        <el-input-number  style="width: 100px;flex:1" v-model="scope.row.fee" placeholder="请输入手续费" controls-position="right" class="mr20" :precision="2" :min="parseFloat(scope.row.min_fee)" :max="scope.row.max_fee"></el-input-number>
                        <p class="ml20">{{$t('main.passagewayMenu.minimum')}}：{{scope.row.min_fee}}{{$t('main.passagewayMenu.maximum')}}：{{scope.row.max_fee}} </p>
                    </div>
                    
                </template>
            </el-table-column>
            <el-table-column :label="$t('main.passagewayMenu.singleCost')" align="center">
                <template slot-scope="scope">
                    <div style="display:flex;align-center">
                        <el-input-number style="width: 100px;" v-model="scope.row.fee_num" placeholder="请输入手续费" controls-position="right" class="mr20" :precision="2" :min="parseFloat(scope.row.min_fee_num)" :max="parseFloat(scope.row.max_fee_num)"></el-input-number>
                        <p class="ml20">{{$t('main.passagewayMenu.minimum')}}：{{scope.row.min_fee_num}}{{$t('main.passagewayMenu.maximum')}}：{{scope.row.max_fee_num}} </p>
                    </div>
                    
                </template>
            </el-table-column>
        </el-table>
        <div class="textCenter">
            <el-button type="primary" v-loading="bottonLoading"  @click="onSubmit">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    export default {
        data(){
            return{
                tableList:[],
                spanArr:[],
                loading:false,
                bottonLoading:false
            }
        },
        props:{
            id:{
                type:Number
            },
            show:{
                type:Boolean,
                default:false
            }
        },
        created(){
            // this.$api.traderFeeList({trader_id:'68'}).then(res => {
            this.loading = true
            this.$api.getRepayFeeList({id:this.id}).then(res => {
                this.loading = false
                if (res.status == 1) {
                    this.tableList = res.data
                }
            })
        },
        methods:{
            close(){
                this.$emit('update:show',false)
            },
            onSubmit(){
                if(this.tableList.length == 0){
                    return
                }
                let errorString = ''
				var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,4}|[1-9][0-9]*\.\d{1,4})))$/
                for (let i = 0; i < this.tableList.length; i++) {
                    // if(!this.tableList[i].fee){
                    //     errorString = '请输入费率'
                    //     break
                    // }
                    // if(!this.tableList[i].fee_num){
                    //     errorString = '请输入单笔费用'
                    //     break
                    // }
					if (this.tableList[i].fee === '' || (!pattern.test(this.tableList[i].fee) && this.tableList[i].fee != 0 && this.tableList[i].fee != '0')) {
						errorString = '手续费只能是数字，最多4位小数'
						break;
					} 
					if (this.tableList[i].fee_num === '' || (!pattern.test(this.tableList[i].fee_num) && this.tableList[i].fee_num != 0 && this.tableList[i].fee_num != '0')) {
						errorString = '单笔费用只能是数字，最多4位小数'
						break;
					} 
                }
                if (errorString != '') {
                    this.$message({
                        type: 'error',
                        message: errorString
                    })
                    return
                }
                this.bottonLoading = true
                let obj = {
                    id:this.id,
                    data:JSON.stringify(this.tableList)
                }
                this.$api.getSetRepayFee(obj).then(res => {
                    this.bottonLoading = false
                    if (res.status == 1) {
                        this.$emit('update:show',false)
                    }
                })
                
            },
        }
    }
</script>